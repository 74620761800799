export const grantSubscription = (host, token, email) => {
  let link = `${host}/admin/users/grant-subscription`;
  const reqBody = { email };
  return fetch(link, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reqBody),
  });
};

export const getEmailLeaks = (host, token, type, value) => {
  let link = `${host}/admin/protect/email-leaks?${type}=${value}`;
  return fetch(link, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserInfo = (host, token, email) => {
  let link = `${host}/admin/users/info?email=${email}`;
  return fetch(link, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const handleUserDelete = (host, token, email) => {
  let link = `${host}/admin/users/soft-delete`;
  return fetch(link, {
    method: "DELETE",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const familyMemberDelete = (host, token, email, memberEmail) => {
  let link = `${host}/admin/users/remove-family-member`;
  return fetch(link, {
    method: "DELETE",
    body: JSON.stringify({ email, memberEmail }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const familyMemberAdd = (host, token, body) => {
  let link = `${host}/admin/users/add-family-member`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addAdditionalEmailsMember = (host, token, body) => {
  let link = `${host}/admin/users/bulk-update-additional-emails`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setNewPassword = (host, token, email) => {
  let link = `${host}/admin/users/password-update`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setPhoneNumber = (host, token, phone, email) => {
  let link = `${host}/admin/users/set-phone`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify({ phone, email }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const confirmUserEmail = (host, token, email) => {
  let link = `${host}/admin/users/activate-email`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changeUserEmail = (
  host,
  token,
  email,
  newEmail,
  forceReplace = false,
  skipVerification = false,
) => {
  let link = `${host}/admin/users/change-email`;
  return fetch(link, {
    method: "PUT",
    body: JSON.stringify({ email, newEmail, forceReplace, skipVerification }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDashboardLeaks = (host, token, type, value) => {
  let link = `${host}/admin/protect/dashboard-leaks-info?${type}=${value}`;
  return fetch(link, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBrowserHistory = (host, token, type, value, page = 1) => {
  let link = `${host}/admin/protect/browser-history?${type}=${value}&limit=10&page=${page}`;
  return fetch(link, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const handleSendPaymentSms = (host, token, phone) => {
  let link = `${host}/admin/users/payment-link/sms`;
  return fetch(link, {
    method: "POST",
    body: JSON.stringify({ phone }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
