import { login } from "./auth";
import {
  addBulkWBDListDomain,
  addWBDListDomain,
  getWBDListDomains,
  removeWBDListDomain,
  searchWBDListDomain,
} from './WBDList';
import {
  getAPIKey,
  getSheetData,
  syncSheet,
  updateAPIKey,
  updateSheetData,
} from './googleSheets';
import {
  createAppMessage,
  deleteAppMessage,
  getAllAppMessages,
  getAppMessage,
  updateAppMessage,
} from './appMessaging';
import {
  addCategory,
  deleteCategory,
  getCategory,
  getCategoryData,
  getCategoryForDomains,
  syncCategory,
  updateCategory,
} from './categories';
import {
  actionDomainsAPI,
  changeOptions,
  checkCurrentDomain,
  createOptions,
  getApiDataPoints,
  getDomain,
  getDomainList,
  getNotifications,
  getOptions,
  postApiDataPoints,
  postNotifications,
  getDomainShop,
  getDomainsLabels,
  postDomainShop,
  syncCurrentCategory,
  syncCurrentList,
  getDomainsImages,
  postDomainsImages,
  getRuleForDomain,
  changeRuleForDomain,
  checkCacheDomain,
} from "./domains";
import {
  currentCoupon,
  getCoupons,
  getOffersFile,
  getStatusImport,
  postImportCSV,
  postSaveCoupon,
} from "services/api/coupons";
import { currentFeedback, getFeedBacks } from "./feedback";
import {
  getHighLoadMode,
  getSmsRestrictions,
  setHighLoadMode,
  setSmsRestrictions,
} from "./settings";
import { getArticle, createArticle, getBlogList, postImage } from "./blog";
import { editGiftCard, getGiftCards } from "./giftCards";
import {
  changeDataBoutique,
  createBoutique,
  createBulkBoutique,
  getBoutiques,
  getCurrentBoutique,
} from "./boutiques";
import {
  decodeUrl,
  favoriteIdProduct,
  favoriteLatest,
  favoritePrimary,
  favoriteSimilar,
  getOffersList,
  removeFavoriteMainProduct,
  removeOfferById,
  researchProduct,
  sendCouponsForMainProduct,
} from "./researcher";
import {
  createNewSimilarProduct,
  getMatchedProducts,
  getPrices,
  getProducts,
  initialProductID,
  refreshProducts,
  removeProductID,
  updateAppliedCoupons,
  updateInitialProductID,
  updatePrice,
} from "./products";
import {
  getEmailLeaks,
  grantSubscription,
  getDashboardLeaks,
  getBrowserHistory,
  handleSendPaymentSms,
  getUserInfo,
  handleUserDelete,
  setNewPassword,
  changeUserEmail,
  confirmUserEmail,
  familyMemberDelete,
  familyMemberAdd,
  setPhoneNumber,
  addAdditionalEmailsMember,
} from "./fvPlus";

import { getYoutubers, deleteYoutubers, addYoutubers } from './youtubers';

export const FRAME_HOST = {
  development: "https://widget-dev.franceverif.fr",
  local: "http://localhost:3015",
  development2: "https://widget-dev2.franceverif.fr",
  stage: "https://widget-stage.franceverif.fr",
  production: "https://widget.franceverif.fr",
}[process.env.REACT_APP_ENVA || "production"];

export const SITE_URL = {
  development: "https://franceverif-dev.franceverif.fr",
  development2: "https://franceverif-dev2.franceverif.fr",
  local: "http://localhost:3020",
  stage: "https://franceverif-stage.franceverif.fr",
  production: "https://franceverif.fr",
}[process.env.REACT_APP_ENVA || "production"];

export const API_URL = {
  development: "https://API-GATEWAY-dev.franceverif.fr",
  development2: "https://api-gateway-dev2.franceverif.fr",
  local: "http://localhost:3000",
  stage: "https://API-GATEWAY-stage.franceverif.fr",
  production: "https://API-GATEWAY.franceverif.fr",
}[process.env.REACT_APP_ENVA || "production"];

class API {
  host = API_URL;
  client;
  _token;
  _listeners = [];

  constructor() {}

  addEventListener(listener) {
    const idx = this._listeners.length;
    this._listeners.push(listener);
    return () => {
      this._listeners = this._listeners.filter((l, i) => i !== idx);
    };
  }

  _initRequest(fn, isSecure = true) {
    if (isSecure && !this.token) {
      return Promise.reject({ code: 401, message: "No token" });
    }
    return fn;
  }

  errorHandler = (e) => {
    this._listeners.forEach((l) => l("error", e));
    throw e;
  };

  successHandler = (res) => {
    this._listeners.forEach((l) => l("response", res));
    return res;
  };

  login = this._initRequest(login.bind(this, this.host), false);

  getWBDListDomains = (...arg) =>
    this._initRequest(
      getWBDListDomains
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  addWBDListDomain = (...arg) =>
    this._initRequest(
      addWBDListDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  addBulkWBDListDomain = (...arg) =>
    this._initRequest(
      addBulkWBDListDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  removeWBDListDomain = (...arg) =>
    this._initRequest(
      removeWBDListDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  searchWBDListDomain = (...arg) =>
    this._initRequest(
      searchWBDListDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getAPIKey = (...arg) =>
    this._initRequest(
      getAPIKey
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  updateSheetData = (...arg) =>
    this._initRequest(
      updateSheetData
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  updateAPIKey = (...arg) =>
    this._initRequest(
      updateAPIKey
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getSheetData = (...arg) =>
    this._initRequest(
      getSheetData
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  syncSheet = (...arg) =>
    this._initRequest(
      syncSheet
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getAllAppMessages = (...arg) =>
    this._initRequest(
      getAllAppMessages
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getAppMessage = (...arg) =>
    this._initRequest(
      getAppMessage
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  createAppMessage = (...arg) =>
    this._initRequest(
      createAppMessage
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  updateAppMessage = (...arg) =>
    this._initRequest(
      updateAppMessage
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  deleteAppMessage = (...arg) =>
    this._initRequest(
      deleteAppMessage
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCategories = (...arg) =>
    this._initRequest(
      getCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCategoriesDomains = (...arg) =>
    this._initRequest(
      getCategoryForDomains
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  addCategory = (...arg) =>
    this._initRequest(
      addCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  updateCategory = (...arg) =>
    this._initRequest(
      updateCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCategoryData = (...arg) =>
    this._initRequest(
      getCategoryData
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  syncCategory = (...arg) =>
    this._initRequest(
      syncCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  deleteCategory = (...arg) =>
    this._initRequest(
      deleteCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCouponsData = (...arg) => {
    return this._initRequest(
      getCoupons
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getLinkToExportOffers = (...arg) => {
    return this._initRequest(
      getOffersFile
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  postImportCSVFile = (...arg) => {
    return this._initRequest(
      postImportCSV
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getStatusImportCSVFile = (...arg) => {
    return this._initRequest(
      getStatusImport
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getFeedBackData = (...arg) => {
    return this._initRequest(
      getFeedBacks
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  actionsCurrentFeedBack = (...arg) =>
    this._initRequest(
      currentFeedback
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCouponCurrent = (...arg) =>
    this._initRequest(
      currentCoupon
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  saveNewCoupon = (...arg) =>
    this._initRequest(
      postSaveCoupon
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getDomains = (...arg) =>
    this._initRequest(
      actionDomainsAPI
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getCurrentDomains = (...arg) =>
    this._initRequest(
      getDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getListDomains = (...arg) =>
    this._initRequest(
      getDomainList
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  syncCurrentCategoryId = (...arg) =>
    this._initRequest(
      syncCurrentCategory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  syncCurrentListDomain = (...arg) =>
    this._initRequest(
      syncCurrentList
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getRulesForDomain = (...arg) =>
    this._initRequest(
      getRuleForDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  changeRulesForDomain = (...arg) =>
    this._initRequest(
      changeRuleForDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getDomainShop = (...arg) =>
    this._initRequest(
      getDomainShop
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  postDomainShop = (...arg) =>
    this._initRequest(
      postDomainShop
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getDomainsLabels = (...arg) =>
    this._initRequest(
      getDomainsLabels
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getDomainsImages = (...arg) =>
    this._initRequest(
      getDomainsImages
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  postDomainsImages = (...arg) =>
    this._initRequest(
      postDomainsImages
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  checkURL = (...arg) =>
    this._initRequest(
      checkCurrentDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  checkCacheDomain = (...arg) =>
    this._initRequest(
      checkCacheDomain
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getNotifications = (...arg) =>
    this._initRequest(
      getNotifications
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  postNotifications = (...arg) =>
    this._initRequest(
      postNotifications
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getDataPoints = (...arg) =>
    this._initRequest(
      getApiDataPoints
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  postDataPoints = (...arg) =>
    this._initRequest(
      postApiDataPoints
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getHighLoadMode = (...arg) =>
    this._initRequest(
      getHighLoadMode
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  setHighLoadMode = (...arg) =>
    this._initRequest(
      setHighLoadMode
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getSmsRestrictions = (...arg) =>
    this._initRequest(
      getSmsRestrictions
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  setSmsRestrictions = (...arg) =>
    this._initRequest(
      setSmsRestrictions
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  getBlogListData = (...arg) => {
    return this._initRequest(
      getBlogList
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getBoutiquesData = (...arg) => {
    return this._initRequest(
      getBoutiques
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  createBoutique = (...arg) => {
    return this._initRequest(
      createBoutique
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  createBulkBoutique = (...arg) => {
    return this._initRequest(
      createBulkBoutique
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getCurrentBoutique = (...arg) => {
    return this._initRequest(
      getCurrentBoutique
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  changeDataBoutique = (...arg) => {
    return this._initRequest(
      changeDataBoutique
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  createNewArticle = (...arg) => {
    return this._initRequest(
      createArticle
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  currentArticle = (...arg) => {
    return this._initRequest(
      getArticle
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  uploadArticleImage = (...arg) => {
    return this._initRequest(
      postImage
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getGiftCardData = (...arg) => {
    return this._initRequest(
      getGiftCards
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  editGiftCardData = (...arg) => {
    return this._initRequest(
      editGiftCard
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  favoriteLatest = (...arg) => {
    return this._initRequest(
      favoriteLatest
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  removeMainFavoriteProduct = (...arg) => {
    return this._initRequest(
      removeFavoriteMainProduct
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  favoriteIdProduct = (...arg) => {
    return this._initRequest(
      favoriteIdProduct
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  favoriteSimilar = (...arg) => {
    return this._initRequest(
      favoriteSimilar
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  initialProductID = (...arg) => {
    return this._initRequest(
      initialProductID
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getMatchedProducts = (...arg) => {
    return this._initRequest(
      getMatchedProducts
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  createNewSimilarProduct = (...arg) => {
    return this._initRequest(
      createNewSimilarProduct
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  updateInitialProductID = (...arg) => {
    return this._initRequest(
      updateInitialProductID
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  updateAppliedCoupons = (...arg) => {
    return this._initRequest(
      updateAppliedCoupons
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  removeProductID = (...arg) => {
    return this._initRequest(
      removeProductID
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  favoritePrimary = (...arg) => {
    return this._initRequest(
      favoritePrimary
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getOffersList = (...arg) => {
    return this._initRequest(
      getOffersList
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  sendCouponsForMainProduct = (...arg) => {
    return this._initRequest(
      sendCouponsForMainProduct
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  removeOfferById = (...arg) => {
    return this._initRequest(
      removeOfferById
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  decodeUrl = (...arg) => {
    return this._initRequest(
      decodeUrl
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getProducts = (...arg) => {
    return this._initRequest(
      getProducts
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  refreshProducts = (...arg) => {
    return this._initRequest(
      refreshProducts
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  getPrices = (...arg) => {
    return this._initRequest(
      getPrices
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  updatePrice = (...arg) => {
    return this._initRequest(
      updatePrice
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  researchProduct = (...arg) => {
    return this._initRequest(
      researchProduct
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };
  grantSubscription = (...arg) => {
    return this._initRequest(
      grantSubscription
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  getYoutubers = (...arg) => {
    return this._initRequest(
      getYoutubers
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  addYoutubers = (...arg) => {
    return this._initRequest(
      addYoutubers
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  deleteYoutubers = (...arg) => {
    return this._initRequest(
      deleteYoutubers
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  getEmailLeaks = (...arg) => {
    return this._initRequest(
      getEmailLeaks
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  getUserInfo = (...arg) => {
    return this._initRequest(
      getUserInfo
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  handleUserDelete = (...arg) => {
    return this._initRequest(
      handleUserDelete
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  familyMemberDelete = (...arg) => {
    return this._initRequest(
      familyMemberDelete
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  familyMemberAdd = (...arg) => {
    return this._initRequest(
      familyMemberAdd
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  addAdditionalEmailsMember = (...arg) => {
    return this._initRequest(
      addAdditionalEmailsMember
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler),
    );
  };

  setNewPassword = (...arg) => {
    return this._initRequest(
      setNewPassword
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  setPhoneNumber = (...arg) => {
    return this._initRequest(
      setPhoneNumber
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  confirmUserEmail = (...arg) => {
    return this._initRequest(
      confirmUserEmail
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  changeUserEmail = (...arg) => {
    return this._initRequest(
      changeUserEmail
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  getDashboardLeaks = (...arg) => {
    return this._initRequest(
      getDashboardLeaks
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  getBrowserHistory = (...arg) => {
    return this._initRequest(
      getBrowserHistory
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  handleSendPaymentSms = (...arg) => {
    return this._initRequest(
      handleSendPaymentSms
        .call(this, this.host, this.token, ...arg)
        .then(this.successHandler)
        .catch(this.errorHandler)
    );
  };

  set token(t) {
    this._token = t;
  }
  get token() {
    return this._token;
  }
}

export default API;
